/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import 'gsap/src/minified/TimelineMax.min';
import 'gsap/src/minified/TweenMax.min';
import AOS from 'aos';
import simpleParallax from 'simple-parallax-js';
import 'aos/dist/aos.css';
import marquee from 'jquery.marquee';
import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';
import Fancybox from '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import 'selectize';
import 'selectize/dist/css/selectize.css';

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
import MaterializeForm from '@components/materialize-form.js';
import NiceSelect from '@components/jquery.nice-select.js';
import Menu from '@components/menu.js';
import Sticky from '@components/jquery.sticky.js';


/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import animations from '@pages/animations.js';
import contact from '@pages/contact.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': animations,
        'dependencies': [app, Menu, AOS, simpleParallax, marquee]
    },
	{
		'file': main, 
		'dependencies': [app, CookieManager, Sticky, InfiniteAjaxScroll, simpleParallax, NiceSelect, Fancybox]
    },
    {
        'file': contact,
        'dependencies': [app, MaterializeForm],
        'resolve': '#page-contact, #page-carrier, #page-estimate, #page-sale, #page-home, #page-buy-single'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

