/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Menu, AOS, simpleParallax, marquee) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body             = $('body'),
            scrollAreas       = document.querySelectorAll('.scroll-area'),
            $menuWrapper      = $('#mobile-menu'),
            $menuButton       = $('#header .btn-menu'),
            $menuBars         = $menuButton.find('.item-burger > span'),
            $menuClose        = $menuWrapper.find('.item-close'),
            $menuStaggerItems = $menuWrapper.find('[data-stagger-item]')
		;
		

		/*
        |
        | Scroll Areas
        |---------------
        */
		Array.prototype.forEach.call(scrollAreas, element => {
			Scrollbar.init(element);
		});


        /*
		|
		| Menu
		|-------
        */
        const menu = new Menu($menuWrapper, $menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
            .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
			.to($menuBars.eq(0), 0.5, { x: 0, y: 5, rotation: 45, ease: Power1.easeOut }, 'start')
            .to($menuBars.eq(2), 0.5, { x: 0, y: -5, rotation: -45, ease: Power1.easeOut }, 'start')
			.from($menuWrapper, 0.5, { autoAlpha: 0, ease: Power1.easeOut }, 'start+=0.3')
            .staggerFrom($menuStaggerItems, 0.6, { autoAlpha: 0, x: -20, ease: Power1.easeOut }, '0.1', '+=0')
        
        menu.init();

        // AOS apparition au scroll
        AOS.init();

        // Parallax images
        var images = document.querySelectorAll('.parallax');
        new simpleParallax(images, {
            delay: 0,
            orientation: 'down',
            scale: 1.3,
            overfow: false,
        });

        // Agences HP
        $('.agencies-list-title li a').on('mouseenter', function(e) {
            e.preventDefault();
            var position = $(this).closest('li').index();
            changeAgency(position);
        });

        // Agences HP
        $('.markers .marker').on('mouseenter', function(e) {
            e.preventDefault();
            var position = $(this).index();
            changeAgency(position);
        });

        function changeAgency(position) {
            $('.agencies-list-title li').removeClass('active');
            $('.agencies-list-title li:eq('+position+')').addClass('active');

            $('.markers .marker').removeClass('active');
            $('.markers .marker:eq('+position+')').addClass('active');

            $('.agencies-list-image-content:eq('+position+')').animate({
                'left': 0
            }, 500, function () {
                $('.agencies-list-image-content').not(':eq('+position+')').css('left', '-100%').removeClass('active');
                $('.agencies-list-image-content:eq('+position+')').addClass('active');
            });
        }

        // Texte défilant
        $('.highlight-block-content').marquee({
            duration: 10000,
            delayBeforeStart: 0,
            direction: 'left',
            duplicated: true,
            pauseOnHover: true,
            startVisible: true
        });

        // Back to top
        var btn = $('#back-to-top');

        $(window).scroll(function() {
            if ($(window).scrollTop() > 300) {
                btn.addClass('show');
            } else {
                btn.removeClass('show');
            }
        });

        btn.on('click', function(e) {
            e.preventDefault();
            $('html, body').animate({scrollTop:0}, '300');
        });


    }
}
